@font-face {
    font-family: "Be Vietnam";
    src: url("../../assets/fonts/BeVietnamHF-Regular.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: "Be Vietnam";
    src: url("../../assets/fonts/BeVietnamHF-Bold.ttf") format("truetype");
    font-weight: bold;
    font-style: normal;
}
@font-face {
    font-family: "Be Vietnam";
    src: url("../../assets/fonts/BeVietnamHF-ExtraBold.ttf") format("truetype");
    font-weight: 800;
    font-style: normal;
}

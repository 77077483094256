@import "./base/fonts";
@import "./vendor/bootstrap";
@import "./vendor/toastify";

:root {
    --black: #000000;
    --white: #ffffff;
    --primary: #64e1af;
    --secondary: #181832;
    --grey: #b3b3b3;
    --light-grey: #f3f2f2;
    --purple: #4b4baf;
    --blue: #64c8fa;
    --orange: #f09632;
    --yellow: #f0c84b;
    --red: #f07d7d;
    --green: #9bf07d;
    --size-04: 4px;
    --size-08: 8px;
    --size-16: 16px;
    --size-32: 32px;
    --size-48: 48px;
    --size-64: 64px;
    --size-80: 80px;
    --size-120: 120px;
    --size-240: 240px;
}

* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

html,
body {
    font-family: "Be Vietnam", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    hyphens: auto;
    font-size: var(--size-16);
    background-color: var(--white);
    color: var(--secondary);
}

a {
    text-decoration: underline;
    color: var(--secondary);

    &:hover,
    &:focus,
    &:active {
        color: var(--primary);
    }
}

h1 {
    font-size: 64px;
    line-height: 1.5;
    margin-bottom: 24px;
}

h2 {
    margin-bottom: 32px;
    font-size: 48px;
}

p {
    margin-bottom: 0.9rem;

    &.highlight {
        font-size: 24px;
    }
}
